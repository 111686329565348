import { createApp, ref } from "vue";
import App from "./App.vue";
import jquery from "jquery";
import axios from "axios";
import "bootstrap";
import router from "./router/index";
import Vue3CountryIntl from "vue3-country-intl";
// import css
import "vue3-country-intl/lib/vue3-country-intl.css";
const app = createApp(App);
const isLoggedIn = ref(!!localStorage.getItem("stoorToken"));
app.provide("isLoggedIn", isLoggedIn);
app.use(jquery);
app.component(Vue3CountryIntl.name, Vue3CountryIntl);
app.config.globalProperties.$http = axios;
app.config.globalProperties.$hostUrl = process.env.VUE_APP_BACKEND_SERVER;
app.use(router).mount("#app");
