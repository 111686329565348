<template>
  <header class="header-menu-area">
    <!-- end header-top -->
    <div class="header-menu-content pr-150px pl-150px">
      <div class="container">
        <div class="main-menu-content">
          <a href="#" class="down-button"><i class="la la-angle-down"></i></a>
          <div class="row align-items-center">
            <div class="col-lg-2">
              <div class="logo-box">
                <a href="#" class="logo"
                  ><img
                    src="../assets/images/logo1.png"
                    alt="logo"
                    height="90"
                    width="90"
                /></a>
                <div class="user-btn-action">
                  <div
                    class="search-menu-toggle icon-element icon-element-sm shadow-sm mr-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Search"
                  >
                    <i class="la la-search"></i>
                  </div>
                  <div
                    class="off-canvas-menu-toggle cat-menu-toggle icon-element icon-element-sm shadow-sm mr-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Category menu"
                  >
                    <i class="la la-th-large"></i>
                  </div>
                  <div
                    class="off-canvas-menu-toggle main-menu-toggle icon-element icon-element-sm shadow-sm"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Main menu"
                  >
                    <i class="la la-bars"></i>
                  </div>
                </div>
              </div>
            </div>
            <!-- end col-lg-2 -->
            <div class="col-lg-8">
              <div class="menu-wrapper">
                <nav class="main-menu font-weight-bold">
                  <ul>
                    <li>
                      <router-link
                        to="/"
                        aria-controls="aboutus"
                        aria-selected="false"
                      >
                        عن سطور
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        to="/magazines"
                        aria-controls="policy"
                        aria-selected="false"
                      >
                        المجلات العلمية</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/se"> خدمات الباحثين</router-link>
                    </li>
                    <li>
                      <router-link to="/contact-us"> تواصل معنا</router-link>
                    </li>
                    <li v-if="isLoggedIn">
                      <router-link to="/researches"> أبحاثى</router-link>
                    </li>
                  </ul>
                  <!-- end ul -->
                </nav>
                <!-- end nav-right-button -->
              </div>
              <!-- end menu-wrapper -->
            </div>
            <div class="col-lg-2" v-if="!isLoggedIn">
              <router-link to="/login">
                <section class="login-container-head">
                  <button class="login-button-head">
                    <span class="visually-hidden-head">Login</span>
                    تسجيل دخول
                  </button>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/644abd708204860623b04ad1ff456a86cd52a09763455d3f12f82df947d1743f?placeholderIfAbsent=true&apiKey=dce22b09974a4778b0fbee70c15649cb"
                    class="login-icon"
                    alt=""
                  />
                </section>
              </router-link>
            </div>
            <div class="col-lg-2" v-if="isLoggedIn">
              <section class="login-container-head btn-danger">
                <button class="login-button-head btn-danger">
                  <span class="visually-hidden-head"></span>
                  تسجيل خروج
                </button>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/644abd708204860623b04ad1ff456a86cd52a09763455d3f12f82df947d1743f?placeholderIfAbsent=true&apiKey=dce22b09974a4778b0fbee70c15649cb"
                  class="login-icon"
                  alt=""
                />
              </section>
            </div>
            <!-- end col-lg-10 -->
          </div>
          <!-- end row align-items-center -->
        </div>
        <!-- end main-menu-content -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end header-menu-content -->
  </header>
</template>
<script setup>
import { inject } from "vue";
const isLoggedIn = inject("isLoggedIn");
</script>
<style>
.btn-danger {
  color: #fff;
  background-color: #bd2130 !important;
  border-color: #b21f2d !important;
}
</style>
